import React from "react"
import Squeeze from "../layouts/squeeze-landing"
import Base from "../layouts/base"
import SEO from "../components/SEO"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import TextLeftImgRight from "../components/textLeftImgRight"
import ImgLeftTextRight from "../components/imgLeftTextRight"

export default () => (
    <div>
    <SEO
      title="Ceramic Steel Whiteboard vs Glass board"
      description="This is the description"
    />
    <Base>
      <Squeeze>
      <div>
        <div className="row">
          <div className="eight columns">
            <h1 className="mt-3 mb-1 px-1 py-1 bg-yellow border">CeramicSteel Whiteboard vs Glassboard: <span>How to choose the right surface for your application.</span></h1>
            <p className="px-1 mb-1"><i>Updated 4/2/2021</i></p>
          </div>
          <div className="four columns">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/image2-1-1024x1024.jpg"
              className="img-fluid py-1"
          />
          </div>
        </div>
        <div className="row bg-light-grey pt-2">
          <h2 className="mt-1 div-heading mx-1">You don't need to sacrafice your whiteboard's functionality for appearance.</h2>
          <p className="mb-1 px-1">
          These days, many schools and businesses are switching to glassboards because of their looks – but when it comes to whiteboards, it’s never just about appearances. 
          </p>
          <p className="px-1 pb-1">
          We won’t argue that glassboards look great hanging on the wall, but do they look great once you actually try to use them? Can they stand up to decades of use and abuse in demanding locations like schools? The reality is that glassboards have many disadvantages when compared to CeramicSteel whiteboards. Not only is CeramicSteel more durable, it’s also easier to clean, simpler to install, and much clearer to read even from the back of the room.
          </p>
        </div>
        <div className="row bg-light-grey py-1">
        <div className="twelve columns pb-1 px-1">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/5vfoO7UvJSM" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="row px-1 py-1">
          <h2 className="mb-1 mt-2 div-heading">Visibility & Contrast</h2>
          <p>CeramicSteel is a smooth, non-porous surface just like you get on a glassboard. The big difference is the level of contrast between dry erase ink and writing surface. Glass is a transparent material – this significantly lowers the level of contrast. Any writing on a glass whiteboard will often appear blurred or fuzzy from a distance because of this. The CeramicSteel surface provides sharp contrast between marker ink and dry erase surface, regardless of the whiteboard’s surface color.</p>
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-vs-glass.jpg"
            className="img-fluid my-2"
          />
          <h2 className="mb-1 mt-2 div-heading">Glare & Reflections</h2>
          <p>Reflections and glare are some of the biggest frustrations for presenters using dry erase boards because they obscure information from the audience. A glassboard really has two reflective panes, one each on the front and back, meaning that any light hitting the board will reflect off both surfaces and make it hard to see any writing or projected images. You can see this happening in the image above. The CeramicSteel is available in multiple finishes from high gloss to matte, all of which are less reflective than glass whiteboards.</p>
          <h2 className="mb-1 mt-2 div-heading">Magnetic Properties</h2>
          <p>The CeramicSteel dry erase surface is essentially a piece of high-quality steel with a porcelain enamel surface fused to the front. This material has strong magnetic properties by nature – no additional backings or special magnets are required. A glassboard has no magnetic properties as standard because glass isn’t a magnetic material! Making a glass whiteboard magnetic requires additional backing materials which come at an added cost.</p>
        </div>
        <div className="row">
          <TextLeftImgRight
            heading="Durability"
            p1="Glass is a fragile material. We've all seen glass break before, and it’s never pretty. Even the tempered glass which most glassboards are made from is prone to cracking and even shattering from only minor drops. The resulting sharp, tiny shards of glass are dangerous and difficult to clean up. Panls CeramicSteel whiteboards don’t have any of these issues – in fact, if you drop your Panls whiteboard it won’t even get a scratch!"
            img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-durability.png"
            buybtn="d-none"
          />
          <TextLeftImgRight
            heading="Ease of Installation"
            p1="CeramicSteel whiteboards are lightweight, making for an easy installation process. At Panls we offer several mounting solutions designed for any wall surface application. Glassboard are often much heavier than a CeramicSteel surface and require difficult to install mounting hardware and at least three people to safely hang the board."
            img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-easy-install.jpg"
            buybtn="d-none"
          />
          <TextLeftImgRight
            heading="Chemical & Bacteria Resistance"
            p1="CeramicSteel and glass are both non-porous, inorganic surfaces. That means both resist bacteria since there are no places for the bacteria to hide. Any bacteria that do land on these surfaces won't be able to grow and can easily be removed with most cleaners. With CeramicSteel, you can freely use abrasive cleaners and even strong stuff like bleach with zero damage to the surface. Don't try that on glass - strong cleaners will damage glass surfaces. Overall, while both surfaces do resist bacteria, CeramicSteel is much easier to clean and can stand up to years of heavy use and regular cleaning with even the strongest cleaners. It's the best choice for classrooms and healthcare facilities."
            img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/bacteria.jpg"
            buybtn="d-none"
          />
        </div>
        <div className="row">
          <h2 className="mt-3 mb-1 px-1 py-1 bg-light-grey border">Summary: Why choose a CeramicSteel Whiteboard over a Glassboard?</h2>
          <p className="mb-1 px-1">
           If any of these features are key factors in your application, a CeramicSteel Whiteboard is the best fit for your whiteboard needs.
          </p>
          
        </div>
        <div className="row mb-1 d-flex text-center">
          <div className="six columns py-2 px-2 bg-light-grey border">
            
            <h3 className="">Durable</h3>
          </div>
          <div className="six columns py-2 px-2 bg-light-grey border">
            
            <h3 className="">Easy to Clean</h3>
          </div>
        </div>
        <div className="row mb-1 d-flex text-center">
          <div className="six columns py-2 px-2 bg-light-grey border">
          
            <h3 className="">Easy to Read</h3>
          </div>
          <div className="six columns py-2 px-2 bg-light-grey border">
       
            <h3 className="">Magnetic</h3>
          </div>
        </div>
        <div className="row mb-1 d-flex text-center">
          <div className="six columns py-2 px-2 bg-light-grey border">
            
            <h3 className="">Bacteria Resistant</h3>
          </div>
          <div className="six columns py-2 px-2 bg-light-grey border">
           
            <h3 className="">Simple Installation</h3>
          </div>
        </div>
        <div className="row mt-3 mb-4 px-1 py-2 d-flex-center bg-light-grey border">
          <h3 className="mb-1 mt-2 div-heading mx-1">Want to learn more?</h3>
          <p className="mb-1 px-1">
            Contact our team today - call <a href="tel:8775717904">(877) 571-7904</a> or email <a href="mailto:sales@panls.com">sales@panls.com</a>. 
          </p>
          <br></br>
        </div>
      </div>
      </Squeeze>
    </Base>
  </div>
)